import * as React from "react"
import {  useTranslation } from "gatsby-plugin-react-i18next";
import SimpleEventView from "./simple-event-view";

const EventSidebar = ({ relatedEvents}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-10">
            <div className="text-lg font-bold">
                {t("related_event")}
            </div>
            <div className="flex flex-col  gap-10 lg:flex-row lg:flex-wrap ">
                {relatedEvents.map((event, i) => <SimpleEventView key={i}  event={event}  />)}
            </div>
        </div>
    )
}

export default EventSidebar;
