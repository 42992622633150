import * as React from "react"
import Helmet from "react-helmet"
import { Link, withPrefix } from "gatsby"
import EventSidebar from "./more-event-sidebar"
import LayoutSENR from "../layout/layout-senr"
import parse from "../../utils/html-parser.options"
import videojs from 'video.js';
import "../../styles/article.css"
import "../../styles/rte.css"
import SEOComponent from "../seo";
import { useTranslation } from "react-i18next"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlusButton from "../elements/PlusButton";
import { LocationOnOutlined, ScheduleOutlined } from "@mui/icons-material"
import eventUtils from "../../utils/event.utils"
const EventPage = ({ data }) => {
    const { t, i18n } = useTranslation();
    const event = data.event
    const siteContent = data.siteContent
    const getEventLink = (event, siteContent) => {
        if(!eventUtils.isInEventYear(event, siteContent.programYear)) {
            return "";
        }
        if(event.interneExterne == "interne") {
            return (<PlusButton external to={siteContent.linkPass} className="no-decoration mr-3 inline-block">{t("register-pass")}</PlusButton>)
        }
        return (
            <>
              {event.signUpLink != null && event.signUpLink != "null" && event.signUpLink !== "" && (
                <PlusButton external to={event.signUpLink} className="no-decoration mr-3 inline-block">
                  {t("register-face-to-face")}
                </PlusButton>
              )}
              {event.signUpDistLink != null && event.signUpDistLink != "null" && event.signUpDistLink !== "" && (
                <PlusButton external to={event.signUpDistLink} className="no-decoration inline-block">
                  {t("register-remote")}
                </PlusButton>
              )}
            </>
          );
    }
    React.useEffect(() => {


        // get videojs from document
        const video = document.getElementsByClassName("video-js")[0];
        if(video == null) {
            console.log("no video found");
            return;
        }
        var options = {
            controlBar: {
                children: [
                  'PlayToggle',
                  'ChaptersButton',
                  //'VolumeMenuButton',
                  'ProgressControl',
                  //'CurrentTimeDisplay',
                  //'TimeDivider',
                  //'LiveDisplay',
                  //'RemainingTimeDisplay',
                  
                  
                  'CaptionsButton',
                  'volumePanel',
                  'DescriptionsButton',
                  //'SubtitlesButton',
                  'qualitySelector',
                  'fullscreenToggle',
                ],
            },
        };
        // init videojs
        videojs(video, options, function () {
            videojs.log('player is ready');
        });
      }, [event]);
    
    var dateFormatted = new Date(event.timeStart).toLocaleDateString(siteContent.language, {
        day: "numeric",
        weekday: "long",
        month: "long",
        year: "numeric",
        timeZone: "UTC"
    }) + " • " + new Date(event.timeStart).toLocaleTimeString(siteContent.language, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC"
    }) + " - " + new Date(event.timeEnd).toLocaleTimeString(siteContent.language, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC"
    })
    
    if(event.isSeveralDate === "true")
        dateFormatted = new Date(event.timeStart).toLocaleDateString(i18n.language, {
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
            timeZone: "UTC"
        }) + " - " + new Date(event.timeEnd).toLocaleDateString(i18n.language, {
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
            timeZone: "UTC"
        }) + " • " + new Date(event.timeStart).toLocaleTimeString(i18n.language, {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC"
        }) + " - " + new Date(event.timeEnd).toLocaleTimeString(i18n.language, {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC"
        })

    //get monday of this week
    const dateWeekEvent = new Date(siteContent.dateStartWeek)
    const monday = new Date(dateWeekEvent.getFullYear(), dateWeekEvent.getMonth(), dateWeekEvent.getDate() - dateWeekEvent.getDay() + 1);
    const startDate = monday;

    // add 6 days to the start date
    const daysInAWeek = 6;
    const endDate = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() - monday.getDay() + daysInAWeek);

    // tous les events qui sont sur plusieurs jours
    const relatedEvents = event.relatedEvents.filter(event => new Date(event.timeStart).getTime() >= new Date(siteContent.dateStartWeek).getTime() && (new Date(event.timeStart).getTime() <= endDate.getTime()));
    
    return (
        <main>
            <LayoutSENR siteContent={siteContent}>
                <SEOComponent title={event.title} />

                <section className="section mt-5 px-5">
                    <div className="flex gap-20 lg:flex-col">
                        <div className="whitespace-normal rte event flex-grow">
                            <div className="flex flex-col gap-5">
                                <div className="flex gap-3  items-center">
                                    <div>
                                    <Link className="underline" to={`/programmation`}>{t("menu_event")}</Link>
                                    </div>
                                    
                                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                    <Link className="underline flex-grow" to={`/event/${event.slug}`}>{event.title}</Link>
                                </div>
                                <div className="flex gap-3  items-center">
                                    <span className="bg-white p-2 px-3 font-medium text-sm text-black rounded-2xl">{event.typeEvent}</span>
                                    <span className="font-semibold">{event.routeName}</span>
                                </div>
                            </div>

                            <h2 className="text-orange-nr text-headline">{event.title}</h2>
                            <div class="border-t border-b flex flex-col gap-5 py-5 font-bold text-lg w-full">
                                <div class="flex gap-2">
                                    <ScheduleOutlined></ScheduleOutlined>
                                    {/* <span>Lundi 2 mai • 09h00 - 12h00</span>  */}
                                    <span class="date">{dateFormatted}</span>
                                </div>
                                <div class="flex gap-2">
                                    <LocationOnOutlined></LocationOnOutlined>
                                    <div class="flex gap-2 flex-col">
                                        <span>{event.locationName ?? "Non Specifié"}</span>
                                        <Link to={`https://www.openstreetmap.org/search?query=${event.locationAddress}`} target="_blank" className="underline">{event.locationAddress}</Link>
                                    </div>
                                </div>
                                <div className="flex gap-3  items-center flex-wrap">
                                    <span className="bg-white p-2 px-3 font-medium text-sm text-black rounded-2xl">{event.presenceEvent}</span>
                                    {
                                        event.typePublic.map((type, i) => <span className="bg-white p-2 px-3 font-medium text-sm text-black rounded-2xl">{type}</span>)
                                    }
                                </div>
                                <div>
                                    {getEventLink(event, siteContent)}
                                </div>
                            </div>
                            <div className="flex flex-wrap my-8 gap-y-8 w-full">
                                {event.participants.map((participant, i) => <div key={i} className={`flex gap-2 items-center w-1/2 ${i%2==0?`pr-1`:`pl-1`} md:w-full md:p-0`}>
                                    { participant.photoURL && <div class="flex-shrink-0"><img src={participant.photoURL} alt={t("picture_of")+participant.fullName} className="rounded-full object-cover h-14 w-14" ></img> </div>}
                                    <div className="flex flex-col gap-2">
                                        <span class="text-lg font-medium ">{participant.fullName != "null" ? participant.fullName:""}</span>
                                        <span class="text-base">{participant.role != "null" ? participant.role:""}</span>
                                    </div>
                                </div>)}

                            </div>
                            <div className="mt-5">
                                {parse(event.content)}
                            </div>

                            
                            
                        </div>
                        <div className="mt-5 ">
                            <div className="w-80 lg:w-full">
                                <EventSidebar relatedEvents={relatedEvents}></EventSidebar>
                            </div>
                        </div>
                    </div>
                </section>
            </LayoutSENR>
        </main>
    )
}
export default EventPage;