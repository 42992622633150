import EventPage from "../../components/event/event";
import { graphql } from "gatsby"
export default EventPage;

export const query = graphql`
  query($slug: String ,$language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteContent (language: {eq: $language}) {
      editionYear
      programYear
      language
      linkPass
      cookieURL
      mediaKit
        personalDataURL
        dateStartWeek
        banner
        
    }
    event(slug: { eq: $slug }, language: {eq: $language}) {
      timeStart
      timeEnd
      typeEvent
      typePublic
      presenceEvent
      routeName
      idLiferay
      isSeveralDate
      slug
      language
      title
      locationName
      interneExterne
      locationAddress
      participants {
        photoURL
        fullName
        role
      }
      content
      signUpLink
      signUpDistLink
      interneExterne
      advices
      relatedEvents {
        slug
        timeEnd
        timeStart
        title
        typeEvent
        typeParcours
        presenceEvent
        typePublic
        routeName
        signUpLink
        signUpDistLink
        locationName
        locationAddress
        idLiferay
        isSeveralDate
      }
    }
  }
`