import { ScheduleOutlined } from "@mui/icons-material";
import {  Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

const SimpleEventView = ({ event }) => {
    const {i18n, t} = useTranslation();
    var dateFormatted = new Date(event.timeStart).toLocaleDateString(i18n.language, {
        day: "numeric",
        weekday: "long",
        month: "long",
        timeZone: "UTC"
    }) + " • " + new Date(event.timeStart).toLocaleTimeString(i18n.language, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC"
    }) + " - " + new Date(event.timeEnd).toLocaleTimeString(i18n.language, {
        hour: "numeric",
        minute: "numeric",
        timeZone: "UTC"
    })
    
    if(event.isSeveralDate === "true")
        dateFormatted = new Date(event.timeStart).toLocaleDateString(i18n.language, {
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
            timeZone: "UTC"
        }) + " - " + new Date(event.timeEnd).toLocaleDateString(i18n.language, {
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
            timeZone: "UTC"
        }) + " • " + new Date(event.timeStart).toLocaleTimeString(i18n.language, {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC"
        }) + " - " + new Date(event.timeEnd).toLocaleTimeString(i18n.language, {
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC"
        })

    return (<Link to={"/event/"+event.slug} className={`event-background bg-${event.interneExterne == "externe" ? "externe" : "interne"}`}>
   <div className="flex flex-col gap-3">
       <time className="hidden" dateTime={event.timeStart}> </time>
       <div className="flex gap-2 text-sm items-center">
           <ScheduleOutlined></ScheduleOutlined>
           <span className={`date text-${event.interneExterne == "externe" ? "interne" : "white"}`}>{dateFormatted}</span>
           </div>
       <div className={`text-${event.interneExterne == "externe" ? "interne" : "text-orange-nr"} font-semibold text-base`}>{event.title}</div>
       <div className="flex gap-2  text-sm items-center">
            <span className="chip-event">{event.typeEvent}</span>
            <span className={`text-${event.interneExterne == "externe" ? "interne" : "white"} font-medium text-base`}>{event.routeName}</span>
       </div>
       <div className="flex gap-2 flex-wrap text-sm">
           {
               event.typePublic.map((type, i) => <span key={i} className="chip-advice">{type}</span>)
           }

       </div>
   </div>
</Link>)
}
export default SimpleEventView;