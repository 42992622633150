import { Link } from "gatsby-plugin-react-i18next";
import * as React from "react"
import { Button } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const PlusButton = ({ to, className, children, external }) => {
    return (
        <div className={className}>
            {external ?
                <a href={to} target="_blank" rel="noopener">
                    <Button role="link" aria-label={children} variant="contained" color="secondary" endIcon={<AddOutlinedIcon></AddOutlinedIcon>}>{children}</Button>
                </a>
            :
                <Button component={Link} to={to} role="link" aria-label={children} variant="contained" color="secondary" endIcon={<AddOutlinedIcon></AddOutlinedIcon>}>{children}</Button>
            }
        </div>
    )
}

export default PlusButton;
