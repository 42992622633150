// create utils function
export default {
    generateNonOverlapping: (events) => {
        var eventsValue = [];
        // find non overlapping events
        for (var i = 0; i < events.length; i++) {
            // check if event is overlapping using some
            var overlaps = eventsValue.some(e => {
                // check if range overlaps
                return rangesOverlap(events[i].timeStart, events[i].timeEnd, e.timeStart, e.timeEnd);
            });
            if (!overlaps) {
                eventsValue.push(events[i]);
            }
        }
        return eventsValue;
    },
    isInEventYear: (event, year) => {
        
        const eventDate = new Date(event.timeStart)
        // Extract the year from the event date
        const eventYear = eventDate.getFullYear();
        // Compare the event year with the specified year and return the result
        return eventYear == year;
    },
    getSeparateEventsByDay: (events) => {
        const eventsSeparateByDay = [];
        events.forEach(event => {
            const date = new Date(event.timeStart);
            const day = date.getDay();
            if (!eventsSeparateByDay[day]) {
                eventsSeparateByDay[day] = [];
            }
            eventsSeparateByDay[day].push(event);
        });
        return eventsSeparateByDay;
    },
    getWeekList: (startDate, language, showMonthDay = false) => {
        const dateFormat = showMonthDay ? { weekday: 'long', day: 'numeric', month: 'long' } : { weekday: 'long' };
        return Array(6).fill(0).map((_, i) => {
            // get formatted date with day of week and day of month
            const date = new Date(startDate);
            date.setDate(date.getDate() + i);
            // get day of week capitalised
            const dayWeek = date.toLocaleString(language, dateFormat);
            //capitalise first letter
            const dayWeekCapitalised = dayWeek.charAt(0).toUpperCase() + dayWeek.slice(1);
            return dayWeekCapitalised;
        })
    },
    isOverlappingEvent: (events, event) => {
        // check if the timestart of event is overlapping using some
        return events.some(e => {
            return (new Date(event.timeStart) <= new Date(e.timeStart) && new Date(event.timeEnd) >= new Date(e.timeStart))
        })
    },
    getNumbersOfEvent: (events, event) => {
        // get the count of events that are equal to timestart
        return events.filter(e => {
            return (new Date(event.timeStart).getTime() == new Date(e.timeStart).getTime())
        }).length
    }
}

function rangesOverlap(x1, x2, y1, y2) {
    if (x1 < y2 && y1 < x2) {
        return true;
    } else {
        return false;
    }
}